//import $ from "jquery";
import WOW from '../../pages/client-admin-app/wow.min'
import $ from "jquery";

let $offset = 190;
if ( $(window).width() < 768) {
    $offset = 80;
} else {
    $offset = 190;
}

$(window).resize(function() {
    if ( $(window).width() < 768) {
        $offset = 80;
    } else {
        $offset = 190;
    }
});

new WOW({
    offset: $offset
}).init();



// let card = $('.main-section--card');
// let layer= $('.hero-screen');
// layer.mousemove(function(e){
//     var ivalueX= (e.pageX * -1 / 20);
//     var ivalueY= (e.pageY * -1 / 20);
//     card.css('transform', 'translate3d('+ivalueX+'px,'+ivalueY+'px, 0)');
// });


function _initPPocAccordion() {
    // add accordion
    $('.pp--accordion-list').css('max-height', '120px');

    $('.pp--accordion-toggler').on('click', (event) => {
        let $target = $(event.currentTarget);
        console.log($(event.currentTarget))
        let $content = $target.closest('.pp--accordion').find('.pp--accordion-list');
        console.log($content)
        $target.toggleClass('active');

        if ($content.height() != 120) {
            $content.css('max-height', 120);
            $content.removeClass('expanded');
        } else {
            $content.css('max-height', $content[0].scrollHeight + "px");
            $content.addClass('expanded');
        }

        $('.pp--accordion-list').not($content).css('max-height', 0);
        $('.pp--accordion-toggler').not($target).removeClass('active');
        $('.pp--accordion-list').not($content).removeClass('expanded');

    });
}

_initPPocAccordion();

$('#modal__video-promo--short').on('hidden.bs.modal', function () {
    let $video = $('.modal__body--video');
    if($($video).length){
        let videoSrc = $(this).find('iframe').attr("src");
        $(this).find('iframe').attr("src", '');
        $(this).find('iframe').attr("src", videoSrc);
    }
    $('#popup--login-form').modal('show');
});


function initSwitchPSItems(){
    let $itemsCount = $(".ps--list .ps--list-item");

    function randomIntFromInterval(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
    }
    const rndInt = randomIntFromInterval(0, $itemsCount.length);

    let checkItem = setInterval(function () {
        if ($('.ps--list .ps--list-item').length) {
            $(".ps--list .ps--list-item").each(function () {
                $(this).removeClass('active')
            });
            $($itemsCount[randomIntFromInterval(0, $itemsCount.length -1)]).addClass('active');
            console.log($($itemsCount[randomIntFromInterval(0, $itemsCount.length-1)]));
            //clearInterval(checkItem);
        }
    }, 2200); // check every 100ms
}
//initSwitchPSItems();

